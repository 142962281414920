import {
  Selector as ReduxSelector,
  TypedUseSelectorHook,
  useSelector,
} from 'react-redux';
import { RootState } from '../reducers/combinedReducer';

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

type AppSelector<A> = ReduxSelector<RootState, A>;

export const selectGroup: AppSelector<Group> = (state) =>
  state.app.currentlySelectedGroup;

export const selectGroupGuid: AppSelector<UUID> = (state) =>
  state.app.currentlySelectedGroup.group_guid as UUID;

export const selectUser: AppSelector<User> = (state) => state.app.user as User;

export const selectCombinedUser: AppSelector<User & PrepareUser> = (state) => ({
  ...state.app.user,
  ...state.prepare.user,
});

export const selectUserGuid: AppSelector<UUID> = (state) =>
  state.app.user.user_guid as UUID;

export const selectIncidentGuid: AppSelector<UUID> = (state) =>
  state.app.currentIncident?.id;

export const selectIncident: AppSelector<IncidentAugmented> = (state) =>
  state.app.currentIncident;

export const selectIncidents: AppSelector<IncidentAugmented[]> = (state) =>
  state.app.incidents;

export const selectGroups: AppSelector<Group[]> = (state) => state.app.groups;

export const selectSelectedWorkspace: AppSelector<UUID> = (state) =>
  state.prepare.workspace.selectedWorkspace as UUID;

export const selectWorkspaceName: AppSelector<string> = (state) =>
  state.prepare.workspace.workspaceName as string;

export const selectIsUploadFileToTeamsLoaded: AppSelector<boolean> = (state) =>
  state.app.isUploadFileToTeamsLoaded as boolean;
